import { useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPost, LayoutContext } from "wcz-layout";
import { ScheduleType } from "../../models/enums/ScheduleType";
import Template from "../../models/Template";
import TemplateTask from "../../models/TemplateTask";
import { apiUrl } from "../../utils/BaseUrl";
import TemplateDialog from "./common/TemplateDialog";

interface CreateTemplateProps {
    scheduleType: ScheduleType | undefined,
    onClose: () => void,
    refetch: () => void,
    department: string
}

export default function CreateTemplate(props: CreateTemplateProps) {
    const [template, setTemplate] = useState({} as Template);
    const { snackbar, t } = useContext(LayoutContext);

    useEffect(() => {
        if (props.scheduleType) {
            setTemplate({
                department: props.department,
                scheduleType: props.scheduleType,
                tasks: [{ taskIndex: 0, text: "", textEn: "", omittedUsers: [], includeOnlyUsers: [] }]
            })
        }
        // eslint-disable-next-line
    }, [props.scheduleType]);

    const { mutate: create } = useMutation((data: Template) => fetchPost(apiUrl + "/v1/template", data), {
        onSuccess: () => { props.onClose(); snackbar({ message: t("Created"), severity: 'success' }); props.refetch(); },
    });

    const handleOnSubmit = () => {
        const indexedTasks = template.tasks.map((task, index) => {
            return { ...task, taskIndex: index } as TemplateTask;
        });

        create({ ...template, tasks: indexedTasks });
    }

    return <TemplateDialog open={!!props.scheduleType} onClose={props.onClose} onSubmit={handleOnSubmit} title={t("CreateTemplate")}
        tasks={template.tasks} setTasks={tasks => setTemplate({ ...template, tasks: tasks })} refetch={props.refetch} />
}