import { Add, Create } from '@mui/icons-material';
import { Autocomplete, Card, CardActionArea, CardHeader, Container, Grid, IconButton, TextField } from '@mui/material';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import { isEmpty, uniqBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { fetchGet, LayoutContext } from 'wcz-layout';
import CreateTemplate from '../components/templates/CreateTemplate';
import EditTemplate from '../components/templates/EditTemplate';
import { ScheduleType } from '../models/enums/ScheduleType';
import Template from '../models/Template';
import User from '../models/User';
import { apiUrl, peoplesoftUrl } from '../utils/BaseUrl';

export default function Templates() {
    const [selectedUser, setSelectedUser] = useState<User>({} as User);
    const [activeUsers, setActiveUsers] = useState<User[]>([] as User[]);
    const [createSchedule, setCreateSchedule] = useState<ScheduleType | undefined>(undefined);
    const [editTemplate, setEditTemplate] = useState<Template>({} as Template);
    const { changeTitle, user, t, i18n } = useContext(LayoutContext);
    const isFetching = !!useIsFetching();

    useQuery<User[]>(["users"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=companyCode==530;status==Active`, signal), {
        onSuccess: (data) => {
            const transformedData: User[] = data.map(u => ({ ...u, fullDepartmentName: `${u.departmentId} (${u.departmnetDescription})` }));
            setActiveUsers(transformedData);
        }
    });

    const { data: templates = [], refetch } = useQuery<Template[]>(["templates", selectedUser.departmentId], ({ signal }) => fetchGet(`${apiUrl}/v1/template?department=${selectedUser.departmentId}`, signal), {
        enabled: !!selectedUser.departmentId,
    });

    // eslint-disable-next-line
    useEffect(() => changeTitle(t("Templates")), [i18n.language]);

    useEffect(() => {
        if (activeUsers.length && !selectedUser.employeeId)
            setSelectedUserByEmployeeId(user.id);

        // eslint-disable-next-line
    }, [activeUsers]);

    const setSelectedUserByEmployeeId = (employeeId: string) => {
        const foundUser: User | undefined = activeUsers?.find(u => u.employeeId === employeeId);
        if (foundUser)
            setSelectedUser(foundUser);
    }

    const handleOnDepartmentDescriptionChange = (user: User) => {
        const foundDepartment: User | undefined = activeUsers?.find(u => u.departmnetDescription === user.departmnetDescription);
        if (foundDepartment)
            setSelectedUser(foundDepartment);
    }

    const uniqueUsersDepartmentDescriptions = useMemo(() => activeUsers ? uniqBy(activeUsers.filter(u => u.categoryGroup === "FD" || u.categoryGroup === "FI"), "departmnetDescription") : [], [activeUsers]);

    return (
        <React.Fragment>
            <Container sx={{ mb: 1 }}>
                <Grid container spacing={2} justifyContent="center" direction="column" sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                        {!isEmpty(selectedUser) &&
                            <Autocomplete
                                value={selectedUser}
                                options={uniqueUsersDepartmentDescriptions}
                                getOptionLabel={option => option.fullDepartmentName}
                                onChange={(e, value) => value && handleOnDepartmentDescriptionChange(value)}
                                renderInput={(params) => <TextField {...params} label={t("Departments")} fullWidth />}
                            />
                        }
                    </Grid>

                    {templates.map(template =>
                        <Grid item xs={12} sm={6} key={template.id}>
                            <Card>
                                <CardActionArea onClick={() => setEditTemplate(template)}>
                                    <CardHeader title={t(template.scheduleType)} action={<IconButton><Create /></IconButton>} />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}

                    {!templates.some(template => template.scheduleType === ScheduleType.Daily) &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionArea onClick={() => setCreateSchedule(ScheduleType.Daily)} disabled={isFetching || isEmpty(selectedUser)}>
                                    <CardHeader title={`${t("AddTemplate")}: ${t("Daily").toLowerCase()}`} action={<IconButton><Add /></IconButton>} />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }

                    {!templates.some(template => template.scheduleType === ScheduleType.Weekly) &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionArea onClick={() => setCreateSchedule(ScheduleType.Weekly)} disabled={isFetching || isEmpty(selectedUser)}>
                                    <CardHeader title={`${t("AddTemplate")}: ${t("Weekly").toLowerCase()}`} action={<IconButton><Add /></IconButton>} />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }

                    {!templates.some(template => template.scheduleType === ScheduleType.Monthly) &&
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardActionArea onClick={() => setCreateSchedule(ScheduleType.Monthly)} disabled={isFetching || isEmpty(selectedUser)}>
                                    <CardHeader title={`${t("AddTemplate")}: ${t("Monthly").toLowerCase()}`} action={<IconButton><Add /></IconButton>} />
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Container>

            <CreateTemplate scheduleType={createSchedule} onClose={() => setCreateSchedule(undefined)} refetch={refetch} department={selectedUser.departmentId} />
            <EditTemplate template={editTemplate} setTemplate={setEditTemplate} refetch={refetch} />
        </React.Fragment>
    )
}