import { Card, Container } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchGet, LayoutContext } from 'wcz-layout';
import ChecklistInfo from '../components/home/ChecklistInfo';
import ChecklistTaskCard from '../components/home/ChecklistTaskCard';
import GenerateChecklistButton from '../components/home/GenerateChecklistButton';
import Checklist from '../models/Checklist';
import { ChecklistStatus } from '../models/enums/ChecklistStatus';
import { TaskAnswer } from '../models/enums/TaskAnswer';
import User from '../models/User';
import { apiUrl, peoplesoftUrl } from '../utils/BaseUrl';

export default function HomePage() {
    const [checklists, setChecklists] = useState<Checklist[]>([] as Checklist[]);
    const [activeUsers, setActiveUsers] = useState<User[]>([] as User[]);
    const { user, changeTitle } = useContext(LayoutContext);

    const { refetch } = useQuery<Checklist[]>(["checklists", user.id], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist?userId=${user.id}&status=${ChecklistStatus.NotStarted}&status=${ChecklistStatus.InProgress}`, signal), {
        enabled: !!user.id,
        onSuccess: data => setChecklists(data),
    });

    useQuery<User[]>(["users"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=companyCode==530;status==Active`, signal), {
        onSuccess: (data) => {
            const transformedData: User[] = data.map(u => ({ ...u, fullDepartmentName: `${u.departmentId} (${u.departmnetDescription})` }));
            setActiveUsers(transformedData);
        }
    });

    const isFinished = (checklist: Checklist): boolean => {
        return checklist.tasks!.every(task => {
            if (task.answer === TaskAnswer.Ok || task.answer === TaskAnswer.NotInUse)
                return true;
            if (task.answer === TaskAnswer.Ng && task.remark && task.remark.length >= 6)
                return true;

            return false;
        });
    }

    // eslint-disable-next-line
    useEffect(() => changeTitle("E-checklist"), []);

    return (
        <Container maxWidth="lg">
            <GenerateChecklistButton refetch={refetch} activeUsers={activeUsers} />

            {checklists.map(checklist =>
                <Card sx={(theme) => ({ p: 1, mb: 3, border: isFinished(checklist) ? `solid ${theme.palette.success.main}` : undefined })} key={checklist.id}>
                    <ChecklistInfo key={checklist.id} checklist={checklist} activeUsers={activeUsers} refetch={refetch} allChecklists={checklists} setAllChecklists={setChecklists} />
                    {checklist.tasks?.map(task => <ChecklistTaskCard task={task} refetch={refetch} allChecklists={checklists} setAllChecklists={setChecklists} key={task.id} />)}
                </Card>
            )}
        </Container>
    )
}