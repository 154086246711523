import { CheckBox, CheckBoxOutlineBlank, Close, IndeterminateCheckBox } from "@mui/icons-material";
import { Button, CardActionArea, CardActions, CardHeader, Collapse, Divider, TextField } from "@mui/material";
import { size, some } from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDebounce } from 'use-debounce';
import { fetchPut, LayoutContext } from "wcz-layout";
import Checklist from "../../models/Checklist";
import ChecklistTask from "../../models/ChecklistTask";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { TaskAnswer } from "../../models/enums/TaskAnswer";
import { apiUrl } from "../../utils/BaseUrl";

const MIN_REMARK_LENGTH: number = 6;

interface ChecklistTaskCardProps {
    task: ChecklistTask,
    refetch: () => void,
    allChecklists: Checklist[],
    setAllChecklists: (allChecklists: Checklist[]) => void
}

export default function ChecklistTaskCard(props: ChecklistTaskCardProps) {
    const [remark, setRemark] = useState<string | undefined>(props.task.remark);
    const [debouncedRemark] = useDebounce(remark, 700);
    const [focused, setFocused] = useState<boolean>(false);
    const { snackbar, i18n, t } = useContext(LayoutContext);

    useEffect(() => {
        if (focused)
            updateTaskRemark();
        // eslint-disable-next-line
    }, [debouncedRemark]);

    const { mutate: update } = useMutation((data: Checklist) => fetchPut(apiUrl + "/v1/checklist/" + data.id, data), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); props.refetch(); },
    });

    const getUpdatedTasks = (tasks: ChecklistTask[] | undefined, answer: TaskAnswer | null, newRemark: string | undefined): ChecklistTask[] | undefined => {
        return tasks?.map(task => {
            if (props.task.id === task.id) {
                const updatedTask: ChecklistTask = {
                    ...task,
                    answer: answer,
                    remark: newRemark
                };

                return updatedTask;
            } else {
                return task;
            }
        })
    }

    const updateTaskAnswer = (answer: TaskAnswer) => {
        const updatedChecklists: Checklist[] = props.allChecklists.map(checklist => {
            if (props.task.checklistId === checklist.id) {
                const updatedTasks: ChecklistTask[] | undefined = getUpdatedTasks(checklist.tasks, answer, props.task.remark);

                const updatedChecklist: Checklist = {
                    ...checklist,
                    status: some(updatedTasks, ["answer", null]) ? ChecklistStatus.InProgress : ChecklistStatus.Finished,
                    tasks: updatedTasks
                };

                update(updatedChecklist);
                return updatedChecklist;
            } else {
                return checklist;
            }
        })

        props.setAllChecklists(updatedChecklists);
    }

    const updateTaskRemark = () => {
        const updatedChecklists: Checklist[] = props.allChecklists.map(checklist => {
            if (props.task.checklistId === checklist.id) {
                const updatedTasks: ChecklistTask[] | undefined = getUpdatedTasks(checklist.tasks, props.task.answer, remark);

                const updatedChecklist: Checklist = {
                    ...checklist,
                    tasks: updatedTasks
                };

                update(updatedChecklist);
                return updatedChecklist;
            } else {
                return checklist;
            }
        })

        props.setAllChecklists(updatedChecklists);
    }

    return (
        <Fragment>
            <Divider />

            <CardActionArea onClick={() => updateTaskAnswer(TaskAnswer.Ok)}>
                <CardHeader title={i18n.language === "cs" ? props.task.text : props.task.textEn} avatar={
                    <React.Fragment>
                        {!props.task.answer && <CheckBoxOutlineBlank />}
                        {props.task.answer === TaskAnswer.Ok && <CheckBox color="success" />}
                        {props.task.answer === TaskAnswer.Ng && <Close color="error" />}
                        {props.task.answer === TaskAnswer.NotInUse && <IndeterminateCheckBox color="warning" />}
                    </React.Fragment>
                } />
            </CardActionArea>

            <CardActions>
                <Button size="small" color="inherit" onClick={() => updateTaskAnswer(TaskAnswer.Ng)}>{t("Refuse")}</Button>
                <Button size="small" color="inherit" onClick={() => updateTaskAnswer(TaskAnswer.NotInUse)}>{t("NotInUse")}</Button>
            </CardActions>

            <Collapse in={props.task.answer === TaskAnswer.Ng} timeout="auto" unmountOnExit sx={{ p: 1 }}>
                <TextField label={t("Remark")} value={remark ?? ""} onChange={e => setRemark(e.target.value)} fullWidth size="small" onFocus={() => setFocused(true)}
                    error={size(remark) < MIN_REMARK_LENGTH} helperText={size(remark) < MIN_REMARK_LENGTH && `${size(remark)}/${MIN_REMARK_LENGTH}`} />
            </Collapse>
        </Fragment>
    )
}