import { Assignment, History, Home } from '@mui/icons-material';
import { cyan, yellow } from '@mui/material/colors';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { hasRole, LayoutProvider, LeftDrawerItem, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import Checklists from './pages/Checklists';
import HomePage from './pages/HomePage';
import Templates from './pages/Templates';
import { adminRole } from './utils/UserRoles';

export default function App() {
    const user = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "History", path: "/checklists", icon: <History fontSize="large" /> },
        { title: "Templates", path: "/templates", icon: <Assignment fontSize="large" />, hidden: !hasRole(adminRole) },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={cyan[600]} secondaryColor={yellow[600]} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/checklists' element={<Checklists />} />
                    <Route path='/templates' element={<Templates />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
}