import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import packageJson from '../package.json';

LicenseInfo.setLicenseKey(packageJson.MuiXLicence);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
