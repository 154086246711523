import { isDevelopment } from 'wcz-layout';

let apiUrl: string = "https://api.dev.wistron.eu/sms-checklist";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/sms-checklist";
    peoplesoftUrl = "https://api.wistron.eu/ps";
}

//export microservices base URL
export { apiUrl, peoplesoftUrl }