import { DataGridPremium, GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { useMutation, useQuery } from '@tanstack/react-query';
import { trimEnd } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { fetchGet, fetchPut, hasRole, LayoutContext } from 'wcz-layout';
import { GridToolbar, GridToolbarProps, TableContainer } from 'wcz-x-data-grid';
import DetailPanel from '../components/checklists/DetailPanel';
import Checklist from '../models/Checklist';
import { ChecklistStatus } from '../models/enums/ChecklistStatus';
import { ScheduleType } from '../models/enums/ScheduleType';
import { apiUrl } from '../utils/BaseUrl';
import { adminRole } from '../utils/UserRoles';

export default function Checklists() {
    const { user, changeTitle, snackbar, i18n, t } = useContext(LayoutContext);

    const { data: checklists = [], isLoading } = useQuery<Checklist[]>(["checklists"], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist?${hasRole(adminRole) ? `userDepartment=${trimEnd(user.department, "0")}` : `userId=${user.id}`}`, signal), {
        enabled: !!user.id
    });

    useEffect(() => changeTitle(t("Checklists")), [i18n.language]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'created', headerName: t("Created"), width: 160, type: 'date', valueFormatter: params => moment(params.value).formatDateTime() },
        { field: 'scheduleType', headerName: t("ScheduleType"), width: 140, type: 'singleSelect', valueOptions: Object.values(ScheduleType), },
        { field: 'status', headerName: "Status", width: 140, type: 'singleSelect', valueOptions: Object.values(ChecklistStatus), },
        { field: 'userName', headerName: t("User"), width: 200, hide: !hasRole(adminRole), },
        { field: 'userId', headerName: t("UserId"), width: 150, hide: !hasRole(adminRole), },
        { field: 'userDepartment', headerName: t("Department"), width: 150, hide: !hasRole(adminRole), },
        { field: 'remark', headerName: t("Remark"), width: 300, editable: true, },
    ] as GridColDef[], [user.name]);

    const { mutate: update } = useMutation((req: Checklist) => fetchPut(`${apiUrl}/v1/checklist/${req.id}`, req), {
        onSuccess: () => snackbar({ message: t("Updated"), severity: 'success' }),
    });

    const processRowUpdate = async (row: GridRowModel<Checklist>): Promise<Checklist> => {
        update(row);
        return row;
    };

    return (
        <TableContainer>
            <DataGridPremium rows={checklists} columns={columns} slots={{ toolbar: GridToolbar }} editMode="row" loading={isLoading}
                slotProps={{ toolbar: { hideAddRecord: true } as GridToolbarProps }} processRowUpdate={processRowUpdate}
                getDetailPanelContent={params => <DetailPanel params={params} />} getDetailPanelHeight={() => 200} />
        </TableContainer>
    )
}