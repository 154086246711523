import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPut, LayoutContext } from "wcz-layout";
import Template from "../../models/Template";
import TemplateTask from "../../models/TemplateTask";
import { apiUrl } from "../../utils/BaseUrl";
import TemplateDialog from "./common/TemplateDialog";

interface EditTemplateProps {
    template: Template,
    setTemplate: (template: Template) => void,
    refetch: () => void
}

export default function EditTemplate(props: EditTemplateProps) {
    const { snackbar, t } = useContext(LayoutContext);

    const { mutate: update } = useMutation((data: Template) => fetchPut(apiUrl + "/v1/template/" + data.id, data), {
        onSuccess: () => { props.setTemplate({} as Template); snackbar({ message: t("Updated"), severity: 'success' }); props.refetch(); },
    });

    const handleOnSubmit = () => {
        const indexedTasks = props.template.tasks.map((task, index) => {
            return { ...task, taskIndex: index } as TemplateTask;
        });

        update({ ...props.template, tasks: indexedTasks });
    }

    if (!props.template.id)
        return null;

    return <TemplateDialog open={!!props.template.id} onClose={() => props.setTemplate({} as Template)} onSubmit={handleOnSubmit} title={t("UpdateTemplate")}
        tasks={props.template.tasks} setTasks={tasks => props.setTemplate({ ...props.template, tasks: tasks })} refetch={props.refetch} templateId={props.template.id} />
}