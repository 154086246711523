import { CheckBox, CheckBoxOutlineBlank, Close, IndeterminateCheckBox } from "@mui/icons-material";
import { Box, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { LayoutContext } from "wcz-layout";
import ChecklistTask from "../../models/ChecklistTask";
import { TaskAnswer } from "../../models/enums/TaskAnswer";

interface DetailPanelProps {
    params: GridRowParams
}

export default function DetailPanel(props: DetailPanelProps) {
    const { i18n } = useContext(LayoutContext);

    return (
        <Box sx={{ overflow: 'auto', height: 200 }}>
            <List dense>
                {props.params.row.tasks.map((task: ChecklistTask) => (
                    <ListItem key={task.id}>
                        <ListItemAvatar>
                            {!task.answer && <CheckBoxOutlineBlank />}
                            {task.answer === TaskAnswer.Ok && <CheckBox color="success" />}
                            {task.answer === TaskAnswer.Ng && <Close color="error" />}
                            {task.answer === TaskAnswer.NotInUse && <IndeterminateCheckBox color="warning" />}
                        </ListItemAvatar>
                        <ListItemText primary={i18n.language === "cs" ? task.text : task.textEn} secondary={task.remark} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}