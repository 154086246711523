import { ArrowDropDown, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemText, Menu, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPost, LayoutContext } from "wcz-layout";
import Checklist from "../../models/Checklist";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { ScheduleType } from "../../models/enums/ScheduleType";
import User from "../../models/User";
import { apiUrl } from "../../utils/BaseUrl";

interface GenerateChecklistButtonProps {
    refetch: () => void,
    activeUsers: User[]
}

export default function GenerateChecklistButton(props: GenerateChecklistButtonProps) {
    const [selectedUser, setSelectedUser] = useState<User>({} as User);
    const [searchText, setSearchText] = useState<string>('');
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; } | null>(null);
    const { user, snackbar, t } = useContext(LayoutContext);
    const uniqueDepartments = useMemo(() => props.activeUsers ? uniqBy(props.activeUsers.filter(u => u.categoryGroup === "FD" || u.categoryGroup === "FI"), "departmnetDescription") : [], [props.activeUsers]);
    const [filteredDepartments, setFilteredDepartments] = useState<User[]>([]);

    useEffect(() => {
        if (props.activeUsers.length)
            setSelectedUserByEmployeeId(user.id);

        // eslint-disable-next-line
    }, [props.activeUsers]);

    const setSelectedUserByEmployeeId = (employeeId: string) => {
        const foundUser: User | undefined = props.activeUsers?.find(u => u.employeeId === employeeId);
        if (foundUser)
            setSelectedUser(foundUser);
    }

    useEffect(() => {
        if (uniqueDepartments.length)
            initFilteredDepartments();

        // eslint-disable-next-line
    }, [uniqueDepartments]);

    const initFilteredDepartments = () => setFilteredDepartments(uniqueDepartments);

    const { mutate: create, isLoading: isCreating } = useMutation((request: Checklist) => fetchPost(apiUrl + "/v1/checklist", request), {
        onSuccess: () => { snackbar({ message: t("Created"), severity: 'success' }); props.refetch(); }
    });

    const handleOnCreateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (selectedUser.departmnetDescription)
            create({
                userName: user.name,
                userId: user.id,
                userDepartment: selectedUser.departmentId,
                scheduleType: ScheduleType.Daily,
                status: ChecklistStatus.NotStarted
            });
        else
            setContextMenu({ mouseX: event.clientX, mouseY: event.clientY });
    }

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const filteredRows = uniqueDepartments.filter((row: any) => {
            return row["fullDepartmentName"]?.toString().toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredDepartments(filteredRows);
    };

    const onClose = () => setContextMenu(null);

    return (
        <Grid container justifyContent="center" sx={{ my: 3 }}>
            <ButtonGroup variant="contained">
                <Button onClick={handleOnCreateClick} disabled={isCreating}>{`${t("CreateDailyChecklist")} ${t("For")} ${selectedUser.fullDepartmentName ?? t("Department")}`}</Button>
                <Button size="small" onClick={event => setContextMenu({ mouseX: event.clientX, mouseY: event.clientY })}><ArrowDropDown /></Button>
            </ButtonGroup>

            <Menu open={contextMenu !== null} onClose={onClose} anchorReference="anchorPosition" variant="menu"
                anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
                <Box sx={{ width: 320, maxHeight: 380, p: 1 }} role="presentation">
                    <TextField label={t("Departments")} fullWidth variant="standard" value={searchText} onChange={e => requestSearch(e.target.value)}
                        InputProps={{ startAdornment: <InputAdornment position="start"><Search /></InputAdornment> }} />

                    <List dense>
                        {filteredDepartments.map(department =>
                            <ListItem key={department.departmentId} disablePadding onClick={() => setSelectedUserByEmployeeId(department.employeeId)} selected={selectedUser.departmentId === department.departmentId}>
                                <ListItemButton>
                                    <ListItemText primary={department.departmentId} secondary={department.departmnetDescription} />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Menu>
        </Grid>
    );
}