import { Article, DateRange, Group, Person, PlaylistAddCheck } from "@mui/icons-material";
import { Chip, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import { useContext } from "react";
import { useIsMutating, useMutation } from "@tanstack/react-query";
import { fetchPut, LayoutContext } from "wcz-layout";
import Checklist from "../../models/Checklist";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { TaskAnswer } from "../../models/enums/TaskAnswer";
import User from "../../models/User";
import { apiUrl } from "../../utils/BaseUrl";

interface ChecklistInfoProps {
    checklist: Checklist,
    refetch: () => void,
    activeUsers: User[] | undefined,
    allChecklists: Checklist[],
    setAllChecklists: (allChecklists: Checklist[]) => void
}

export default function ChecklistInfo(props: ChecklistInfoProps) {
    const isMutating = !!useIsMutating();
    const { snackbar, i18n, t } = useContext(LayoutContext);

    const { mutate: update } = useMutation((data: Checklist) => fetchPut(apiUrl + "/v1/checklist/" + data.id, data), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); props.refetch(); }
    });

    const handleOnCheckAll = () => {
        const updatedChecklists: Checklist[] = props.allChecklists.map(checklist => {
            if (props.checklist.id === checklist.id) {
                const updatedChecklist: Checklist = {
                    ...checklist,
                    status: ChecklistStatus.Finished,
                    tasks: checklist.tasks?.map(t => ({ ...t, answer: TaskAnswer.Ok }))
                };

                update(updatedChecklist);
                return updatedChecklist;
            } else {
                return checklist;
            }
        })

        props.setAllChecklists(updatedChecklists);
    }

    const getDepartmentDescription = (): string | undefined => {
        const foundDepartment: User | undefined = props.activeUsers?.find(u => u.departmentId === props.checklist.userDepartment);
        if (foundDepartment)
            return foundDepartment.departmnetDescription;
    }

    const getTranslatedDay = (date: string) => {
        const localLocale = moment(date);
        if (i18n.language === "cs")
            localLocale.locale('cs');

        return localLocale.format('dddd');
    }

    return (
        <Grid container justifyContent="space-around" sx={{ mb: 2 }}>
            <Grid item xs={6} sm={6}>
                <Typography variant="h5" sx={{ px: 1.5 }}>{t(props.checklist.scheduleType)}</Typography>
            </Grid>

            <Grid item xs={6} sm={6} sx={{ textAlign: 'right' }}>
                <IconButton size="small" onClick={handleOnCheckAll} disabled={isMutating}>
                    <PlaylistAddCheck />
                </IconButton>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Chip icon={<Person />} label={props.checklist.userName} sx={{ backgroundColor: 'transparent' }} />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                <Chip icon={<Group />} label={props.checklist.userDepartment} sx={{ backgroundColor: 'transparent' }} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Chip icon={<Article />} label={getDepartmentDescription()} sx={{ backgroundColor: 'transparent' }} />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                {props.checklist.created && <Chip icon={<DateRange />} label={getTranslatedDay(props.checklist.created)} sx={{ backgroundColor: 'transparent' }} />}
            </Grid>
        </Grid>
    )
}